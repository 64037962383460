import { Button, Modal } from "@mui/base";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ColorPicker from "react-pick-color";
import { apiCall } from "../../../utils/action";

function AddNewSummary({ open, handleClose, GetCLSummaryList }) {
  const [color, setColor] = useState("#000001");
  const [summaryTagName, setSummaryTagName] = useState("");
  const [tagDefinition, setTagDefinition] = useState("");

  const handleDialogClose = () => {
    handleClose();
    setSummaryTagName("");
    setTagDefinition("");
    setColor("#000001");
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const handleAddSummaryTag = () => {
    const payload = {
      name: summaryTagName,
      definition: tagDefinition,
      color: color,
    };
    apiCall(
      (response) => {
        const { success, message } = response.data;
        if (success) {
          handleDialogClose();
          GetCLSummaryList();
        } else {
          console.error("Update failed:", message);
        }
      },
      payload,
      "ADD_COMMENT_LIB_SUMMARY"
    );
  };

  return (
    <Dialog className="dialog-container-summary-library" open={open} onClose={handleClose} aria-labelledby="category-modal" aria-describedby="category-modal-description" fullWidth maxWidth="md">
      <DialogTitle className="comment-library-dialog-title">New Summary Item</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2} sx={{ padding: "24px 32px" }}>
          <Grid item xs={12} md={6}>
            <FormControl variant="standard" className="forms-control w-100">
              <label className="input-form-label" id="categoryName" style={{ fontSize: "15px" }}>
                Name
              </label>
              <TextField
                id="summary-name"
                className="input-textfield"
                size="small"
                variant="outlined"
                type="text"
                onChange={(e) => setSummaryTagName(e.target.value)}
                value={summaryTagName}
                name="Name"
                fullWidth
                sx={{ height: "40px", fontSize: "15px" }}
              />
            </FormControl>
            <FormControl variant="standard" className="forms-control w-100" sx={{ marginTop: 2 }}>
              <label className="input-form-label" id="categoryName">
                Definition
              </label>
              <TextField
                id="summary-name"
                className="input-textfield"
                size="small"
                variant="outlined"
                type="text"
                onChange={(e) => setTagDefinition(e.target.value)}
                value={tagDefinition}
                name="Definition"
                multiline
                rows={4}
                fullWidth
                // sx={{width:'625px' }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: { xs: 2, md: 0 } }}>
              <ColorPicker
                className="add-summary-color-picker"
                hideAlpha
                color={color}
                onChange={(color) => setColor(color.hex)}
                theme={{
                  width: "352px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions className="comment-library-summary-action">
        <div className="comment-library-action-btn">
          <Button className="cl-summary-cancel-btn" onClick={handleClose}>
            CLOSE
          </Button>
          <Button className="cl-add-summary-btn" style={{ width: "130px !important" }} onClick={handleAddSummaryTag}>
            SAVE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewSummary;
