import { useState } from 'react';
import { Menu, MenuItem } from "@mui/material";
import ListItemIcon from '@mui/material/ListItemIcon';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
const CLSummaryButtonGroup = ({ handleOpenConfirmationModal, row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(anchorEl);
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    return (<div className="cl-tags_button_group_container">
        {/* Return JSX for the first tab */}
        <div style={{borderRight: '0px'}} className="button_group_tab" onClick={() => handleOpenConfirmationModal(row)}>
            <svg width="14" height="15" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* Return path for the delete button icon */}
                <path d="M3 18C2.45 18 1.97933 17.8043 1.588 17.4125C1.196 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8043 17.0208 14.4125 17.4125C14.0208 17.8043 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z" fill="#D63D3D" />
            </svg>
        </div>
        {/* <div className="button_group_delete" onClick={handleProfileMenuOpen} >
            <svg width="4" height="14" viewBox="0 0 4 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.00016 13.6663C1.54183 13.6663 1.14961 13.5033 0.823496 13.1772C0.49683 12.8505 0.333496 12.458 0.333496 11.9997C0.333496 11.5413 0.49683 11.1488 0.823496 10.8222C1.14961 10.4961 1.54183 10.333 2.00016 10.333C2.4585 10.333 2.851 10.4961 3.17766 10.8222C3.50377 11.1488 3.66683 11.5413 3.66683 11.9997C3.66683 12.458 3.50377 12.8505 3.17766 13.1772C2.851 13.5033 2.4585 13.6663 2.00016 13.6663ZM2.00016 8.66634C1.54183 8.66634 1.14961 8.50301 0.823496 8.17634C0.49683 7.85023 0.333496 7.45801 0.333496 6.99967C0.333496 6.54134 0.49683 6.14884 0.823496 5.82217C1.14961 5.49606 1.54183 5.33301 2.00016 5.33301C2.4585 5.33301 2.851 5.49606 3.17766 5.82217C3.50377 6.14884 3.66683 6.54134 3.66683 6.99967C3.66683 7.45801 3.50377 7.85023 3.17766 8.17634C2.851 8.50301 2.4585 8.66634 2.00016 8.66634ZM2.00016 3.66634C1.54183 3.66634 1.14961 3.50301 0.823496 3.17634C0.49683 2.85023 0.333496 2.45801 0.333496 1.99967C0.333496 1.54134 0.49683 1.14912 0.823496 0.823008C1.14961 0.496341 1.54183 0.333008 2.00016 0.333008C2.4585 0.333008 2.851 0.496341 3.17766 0.823008C3.50377 1.14912 3.66683 1.54134 3.66683 1.99967C3.66683 2.45801 3.50377 2.85023 3.17766 3.17634C2.851 3.50301 2.4585 3.66634 2.00016 3.66634Z" fill="#454545" />
            </svg>
        </div> */}
        {/* Menu component */}
        {/* This will use in future release */}
        {/* <Menu
            id={menuId}
            keepMounted
            elevation={1}
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleMenuClose}
            className="profile-menuitem"
            sx={{ maxWidth: "200px", borderRadius: "4px" }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuItem sx={{ height: "30px", width: "100%" }}>
                <ListItemIcon
                sx={{ minWidth: "26px !important" }}
                >
                    <svg width="20" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8334 15.8333H5.66675C5.20842 15.8333 4.81605 15.6701 4.48967 15.3437C4.16328 15.0173 4.00008 14.6249 4.00008 14.1666V2.49992C4.00008 2.04159 4.16328 1.64922 4.48967 1.32284C4.81605 0.996446 5.20842 0.833252 5.66675 0.833252H11.5001L16.5001 5.83325V14.1666C16.5001 14.6249 16.3369 15.0173 16.0105 15.3437C15.6841 15.6701 15.2917 15.8333 14.8334 15.8333ZM10.6667 6.66658V2.49992H5.66675V14.1666H14.8334V6.66658H10.6667ZM2.33341 19.1666C1.87508 19.1666 1.48272 19.0034 1.15633 18.677C0.829943 18.3506 0.666748 17.9583 0.666748 17.4999V5.83325H2.33341V17.4999H11.5001V19.1666H2.33341Z" fill="#454545" />
                    </svg>
                </ListItemIcon>
                Duplicate
            </MenuItem>
            <br />
        </Menu> */}
    </div>)
}
export default CLSummaryButtonGroup;