import { useState, useRef } from 'react';
import Popper from '@mui/material/Popper';
import ColorPicker from 'react-pick-color';
import "../../../../assets/styles/containers/_clTagsSummary.scss";
import { ClickAwayListener, Grow, MenuList } from "@mui/material";
import { apiCallPut } from '../../../utils/action';

/**
 * A ColorPicker component for the Comment Library summary
 */
const CLColorPicker = ({ row, onColorChange, GetCommentLibTagList }) => {
    // Reference to the element the ColorPicker is anchored to
    const anchorRef = useRef(null);

    // Whether the ColorPicker is open or not
    const [open, setOpen] = useState(false);

    // Currently selected color
    const [selectedColor, setSelectedColor] = useState(row?.color === null ? "#ffffff" : row?.color);

    // Toggle the ColorPicker open or closed
    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    // Close the ColorPicker when a click event occurs outside of it
    const handleClose = (event) => {
        // Check if the click event happened inside the anchor element
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        // Close the ColorPicker
        setOpen(false);
        handleSummaryColorChange(event, row);
    };

    // Handle color change from ColorPicker
    const handleColorChange = (color) => {
        setSelectedColor(color.hex); // Update selected color state
        onColorChange(row.id, color.hex); // Propagate color change to parent component (update table row color)
    };

    const handleSummaryColorChange = (event, row) => {
        const payload = {
            "tag_id": row?.id,
            "name": row?.title,
            "color": row?.color
        };
        apiCallPut(
            (response) => {
                const { success, message } = response.data;
                if (success) {
                    GetCommentLibTagList();
                } else {
                    console.error("Update failed:", message);
                }
            },
            payload,
            "EDIT_TAGS_LIST"
        );
    };

    return (
        <div style={{ paddingTop: "3px", height: "32px" }}>
            {/* The anchor element for the ColorPicker */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    border: "1px solid #E5E8E8",
                    backgroundColor: "white",
                    borderRadius: "6px",
                    width: "168px",
                    height: "32px",
                    padding: "5px",
                    gap: "5px",
                }}
                onClick={handleToggle}
                ref={anchorRef}
            >
                {/* The colored div element */}
                <div
                    style={{
                        width: "18px",
                        height: "18px",
                        borderRadius: "2px",
                        backgroundColor: selectedColor,
                        border: "1px solid #E5E8E8",
                    }}
                ></div>
                {/* The color name */}
                <span className='hex-color-title-text'>{selectedColor}</span>
            </div>
            <Popper
                transition
                open={open}
                disablePortal
                role={undefined}
                placement='left-start'
                anchorEl={anchorRef.current}
                modifiers={[
                    {
                        name: 'arrow',
                        options: {
                            element: '[data-popper-arrow]',
                        },
                    },
                ]}
                style={{ zIndex: 999 }}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: 'center top' }}
                    >
                        {/* The ColorPicker component */}
                        <div className="color-picker-popper" style={{ marginRight: "5px" }}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList>
                                    <ColorPicker
                                        className='color-picker-summary'
                                        hideAlpha
                                        color={selectedColor}
                                        onChange={handleColorChange} // Update to handleColorChange
                                    />
                                    <span className="popper-arrow" data-popper-arrow></span>
                                </MenuList>
                            </ClickAwayListener>
                        </div>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default CLColorPicker;
