import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SectionMenu from "./SectionMenu";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { BootstrapTooltip, filterObjectsUptoCategory, timestamp } from "../../utils/services";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MainTemplateModal from "../../components/TemplateEditors/TemplateModals/MainTemplateModal";
import EditCategorys from "../../components/TemplateEditors/TemplateModals/EditCategorys";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { apiCall } from "../../utils/action";
import { copyCategory, copyLine } from "../../components/TemplateEditors/TemplateAccord/CopyMetaData";
import { motion } from "framer-motion";
import { Grid, Skeleton } from "@mui/material";
import Preloader from "../../helpers/Preloader";
import { updateCategoryData } from "../../store/action";
import ColorIndicators from "./ColorIndicators";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { setEditorListner, } from "../../store/action";
import toast from "react-hot-toast";

const TemplateAccord = (props) => {
  const {
    disableDnD,
    from,
    HeaderTitle,
    innerTemplate,
    AllCategoryList,
    setAllCategoryList,
    accordOpen,
    manualPrompt,
    recommended,
    selectedID,
    lineIndex,
    setlineLoader,
    linedata,
    isInspectionLines,
    lineId,
    lineComId,
    actions,
    duplicateIndex,
    skeletonLoad,
    setSkeletonLoad,
    updateTemplate,
    TriggerClick,
    isInspection,
    isChanged,
    renamingId,
    checkActiveFocus,
    renId,
    setduplicateLineId,
    activeRename,
    duplicateCategoryId,
    setDuplicateCategoryId,
    item,
    LoadList,
    categoryId,
    setLineDuplicate,
    handleChange,
    dragHandleProps,
    templateId,
    categoryActive,
  } = props;

  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [lineText, setLineText] = useState(HeaderTitle);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [isEditable, setIsEditable] = useState(false);
  const [name, setName] = useState();
  const [text, setText] = useState();
  const [opt, setOpt] = useState();
  const [colorIndicatorList, setColorIndicatorList] = React.useState(AllCategoryList?.template?.inspector_flag_list || []);
  const [commentListData, setCommentListData] = React.useState(AllCategoryList?.template?.inspector_flag_list || []);
  const [commentTags, setCommentTags] = React.useState([]);
  const [openColorIndicator, setOpenColorIndicator] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [introDropdown, setIntroDropdown] = useState();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.templates)?.categoryList;
  const selectedTagId = commentTags?.map((data) => data?.id);
  const templateUuid = AllCategoryList?.template;
  const categoryList = AllCategoryList?.template?.categories;
  const categoriesUuid = categoryList?.find((cat) => cat?.category_id === linedata?.category_id);
  const categoryInpectorFlags = [];
  if (categoriesUuid?.inspector_flagging_guids && categoriesUuid?.inspector_flagging_guids?.length > 0) categoryInpectorFlags.push(...categoriesUuid?.inspector_flagging_guids);
  categoriesUuid?.lines
    ?.filter((e) => e?.is_deleted !== 1)
    ?.forEach((e) => {
      if (e?.inspector_flagging_guids && e?.inspector_flagging_guids?.length > 0) categoryInpectorFlags.push(...e?.inspector_flagging_guids);
    });
  if (categoriesUuid?.line_insp_flagging_guids && categoriesUuid?.line_insp_flagging_guids?.length > 0) categoryInpectorFlags.push(...categoriesUuid?.line_insp_flagging_guids);
  categoriesUuid?.lines
    ?.filter((e) => e?.is_deleted !== 1)
    ?.forEach((e) => {
      if (e?.line_insp_flagging_guids && e?.line_insp_flagging_guids?.length > 0) categoryInpectorFlags.push(...e?.line_insp_flagging_guids);
    });

  const editableData = (name, introText, optional, dropdowns) => {
    setName(name);
    setText(introText);
    setOpt(optional);
    setIntroDropdown(dropdowns);
  };

  useEffect(() => {
    const initialTags = linedata?.narrative_categories_tags?.map((item) => ({
      id: item?.tag?.id,
      title: item?.tag?.name,
      color: item?.tag?.color,
    }));
    setCommentTags(initialTags === undefined ? [] : initialTags);
  }, [linedata, setCommentTags]);

  const GetCommentLibTagList = () => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setCommentListData(data);
        } else {
          console.log("else", message);
        }
      },
      formdata,
      "GET_CL_TAG_LIST"
    );
  };

  const GetColorIndicatorList = () => {
    const formdata = {
      template_guid: templateUuid?.guid,
      template_type: templateUuid?.template_type,
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setColorIndicatorList(data);
        } else {
          console.log("else", message);
        }
      },
      formdata,
      "GET_CI_LIST"
    );
  };
  const OpenEditableMenu = () => {
    setIsEditable(!isEditable);
    AllCategoryList?.template?.is_narrative_template === 1 && GetCommentLibTagList();
  };

  const closeEditableMenu = async () => {
    if (from?.split("_")[0] === "CATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      const categoriesData = catList?.findIndex((cat) => cat?.uuid === selectedID);
      catList[categoriesData].prompt = name;
      catList[categoriesData].optional = opt ? 1 : 0;
      catList[categoriesData].updated_at = timestamp();
      filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
      updateTemplate("noToast", "", false, () => {
        addTagToCategory();
      });
      setIsEditable(!isEditable);
    }
    if (from?.split("_")[0] === "SUBCATEGORY") {
      const catList = AllCategoryList?.template?.categories;
      catList?.forEach((category, index) =>
        category?.lines?.forEach((lineValue) => {
          if (lineValue?.line_id === linedata?.line_id) {
            lineValue.prompt = name;
            lineValue.introductory_text = text;
            lineValue.optional = typeof opt === "boolean" ? (opt === true ? 1 : 0) : opt;
            lineValue.updated_at = timestamp();
            filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
            updateTemplate("noToast");
            setIsEditable(!isEditable);
          }
        })
      );
    }
  };

  const addTagToCategory = () => {
    const formdata = {
      tag_ids: selectedTagId,
      category_id: categoryId,
      category_uuid: categoriesUuid?.uuid,
      template_guid: templateUuid?.guid,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response?.data;
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      },
      formdata,
      "ADD_TAG_TO_CATEGORY"
    );
  };

  const handleFocus = (event) => {
    event?.target?.select();
    checkActiveFocus();
  };

  const AccordionEdges = (expanded) => {
    if (expanded?.split("_")[0] === "SUBCATEGORY") {
      return "52px 64px";
    } else if (expanded?.split("_")[0] === "DEFAULTS") {
      return "10px 40px";
    } else if (expanded?.split("_")[0] === "comments") {
      return "52px 64px";
    } else if (from?.split("_")[0] === "additional") {
      return "40px 40px 10px 40px";
    } else if (expanded?.split("_")[0] === "details") {
      return "0px";
    } else {
      return "0px";
    }
  };

  const BorderRadius = (from) => {
    if (from?.split("_")[0] !== "SUBCATEGORY") {
      return "4px";
    }
  };

  const BorderBottomAccord = (from, isOpen) => {
    if (from?.split("_")[0] === "SUBCATEGORY" && isOpen) {
      return "none !important";
    } else if (from?.split("_")[0] === "SUBCATEGORY" && !isOpen) {
      return "1px solid #e7e7e9 ";
    }
  };

  const RenameCategoryList = (e, name, id) => {
    if (checkForEmpty(name)) {
      e.stopPropagation();
      renamingId(id);
      setIsTitleEdit(true);
      TriggerClick();
    }
  };

  const ChangeCategoryText = (from) => {
    const catList = AllCategoryList?.template?.categories;
    const updatedCategories = [];
    let showMessage;
    if (from?.split("_")[0] === "CATEGORY") {
      catList?.forEach((cat) => {
        if (cat?.uuid === renId) {
          cat.prompt = lineText;
          cat.updated_at = timestamp();
          updatedCategories.push(cat);
        }
      });
      showMessage = "Category has been renamed";
    } else if (from?.split("_")[0] === "SUBCATEGORY") {
      catList?.forEach((cat) =>
        cat?.lines?.forEach((line) => {
          if (line?.line_id === renId) {
            line.prompt = lineText;
            line.updated_at = timestamp();
            updatedCategories.push(cat);
          }
        })
      );
      showMessage = "Line has been renamed";
    } else if (from?.split("_")[0] === "additional") {
      catList?.forEach((cat) =>
        cat?.lines?.forEach((line) => {
          if (line?.line_id === lineId) {
            line?.line_input_controls?.forEach((ip) => {
              if (ip?.line_input_control_id === renId) {
                ip.prompt = lineText;
                ip.updated_at = timestamp();
                updatedCategories.push(cat);
              }
            });
          }
        })
      );
    } else if (from?.split("_")[0] === "comments") {
      catList?.forEach((cat) =>
        cat?.lines?.forEach((line) => {
          if (line?.line_id === lineComId) {
            line?.comments?.forEach((com) => {
              if (com?.comment_id === renId) {
                com.prompt = lineText;
                com.title = lineText;
                com.updated_at = timestamp();
                com.is_mannual_prompt = 1;
                updatedCategories.push(cat);
              }
            });
          }
        })
      );
    }
    dispatch(updateCategoryData(updatedCategories));
    if (showMessage) updateTemplate("showToast", showMessage, true);
    else updateTemplate("noToast");
  };

  const RenameExisting = (id) => {
    activeRename();
    renamingId(id);
  };

  useEffect(() => {
    setColorIndicatorList(AllCategoryList?.template?.inspector_flag_list);
  }, [AllCategoryList?.template?.inspector_flag_list]);

  const DeleteLines = (id, from) => {
    const catList = AllCategoryList?.template?.categories;
    let toastStatus = null;
    let toastMessage = "";
    if (from?.split("_")[0] === "SUBCATEGORY") {
      catList?.map((cat) => {
        cat?.lines?.map((line) => {
          if (line?.line_id === id) {
            line.is_deleted = 1;
            line.updated_at = timestamp();
            toastStatus = "showToast";
            toastMessage = "Line has been deleted";
            dispatch(updateCategoryData([cat]));
          }
          return line;
        });
        return cat;
      });
    } else if (from?.split("_")[0] === "CATEGORY") {
      catList?.map((cat) => {
        if (cat?.uuid === id) {
          cat.is_deleted = 1;
          cat.updated_at = timestamp();
          toastStatus = "showToast";
          toastMessage = "Category has been deleted";
        }
        return cat;
      });
      dispatch(updateCategoryData(catList));
    } else if (from?.split("_")[0] === "comments") {
      catList?.map((cat) => {
        cat?.lines?.map((line) => {
          line?.comments?.map((comm) => {
            if (comm?.comment_id === id) {
              comm.is_deleted = 1;
              comm.updated_at = timestamp();
              toastStatus = "noToast";
              dispatch(updateCategoryData([cat]));
            }
            return comm;
          });
          return line;
        });
        return cat;
      });
    } else if (from?.split("_")[0] === "additional") {
      catList?.forEach((catValue) => {
        catValue?.lines?.forEach((lineValue) => {
          if (lineValue?.line_id === lineId) {
            lineValue?.line_input_controls?.forEach((ip) => {
              if (ip?.line_input_control_id === id) {
                ip.is_deleted = 1;
                ip.updated_at = timestamp();
                toastStatus = "noToast";
                dispatch(updateCategoryData([catValue]));
              }
              return ip;
            });
          }
          return lineValue;
        });
        return catValue;
      });
    }
    if (toastStatus !== null) {
      if (toastStatus === "noToast") {
        updateTemplate("noToast");
      } else {
        updateTemplate(toastStatus, toastMessage, true);
      }
      setAllCategoryList((prev) => {
        const newCategoryList = { ...AllCategoryList };
        newCategoryList.template.categories = catList;
        newCategoryList.template.categories.updated_at = timestamp();
        return newCategoryList;
      });
    }
  };

  const reorderFromDuplicate = (newItem, index, items) => {
    let reorderedItems = [];
    const firstHalf = items.slice(0, index + 1);
    const secondHalf = items.slice(index + 1);
    reorderedItems = [...firstHalf, newItem, ...secondHalf];
    reorderedItems = reorderedItems.map((reorderedItem, reorderedItemIndex) => {
      return {
        ...reorderedItem,
        order: reorderedItemIndex + 1,
        updated_at: timestamp(),
      };
    });
    return [...reorderedItems];
  };

  const figureOutNewNameForDuplicate = (name, data) => {
    let newName = name;
    let matches = null;
    let maxNumberFound = 1;
    if ((matches = name.match(/(^[^0-9]+) (\d+)$/))) {
      newName = matches[1];
      maxNumberFound = matches[2];
    }
    data.map((datum) => {
      if ((matches = datum.prompt.match(new RegExp(String.raw`${newName} (\d+)$`))) && datum.is_deleted !== 1) {
        maxNumberFound = matches[1] > maxNumberFound ? matches[1] : maxNumberFound;
      }
    });
    maxNumberFound++;
    return `${newName} ${maxNumberFound}`;
  };

  const duplicateCategory = () => {
    const formdata = {
      template_id: templateId,
      category_id: categoryId
    };
    apiCall(
      (response) => {
        const { status_code, data, message, success } = response.data;
        if (success) {
          const index = AllCategoryList?.template?.categories?.findIndex((cat) => cat?.id === categoryId);
          const catList = AllCategoryList?.template?.categories;
          catList.splice(index + 1, 0, data);
          setAllCategoryList((prev) => {
            const newCategoryList = { ...AllCategoryList };
            newCategoryList.template.categories = catList;
            newCategoryList.template.categories.updated_at = timestamp();
            return newCategoryList;
          });
        } else {
          toast.error(message);
        }
      },
      formdata,
      "DUPLICATE_CATEGORY"
    );
  }

  const duplicateCateOrLine = (id, from, name) => {
    let catList = AllCategoryList?.template?.categories;
    let newLine = null;
    let newCategory = null;
    let toastMessage = null;
    if (from?.split("_")[0] === "SUBCATEGORY") {
      setduplicateLineId(lineIndex + 1);
      toastMessage = "Line has been duplicated.";
      catList?.map((cat) => {
        cat?.lines?.map((line, index) => {
          if (cat?.category_id === categoryId && line?.line_id === id) {
            newLine = {
              ...copyLine(line),
              prompt: figureOutNewNameForDuplicate(name, cat.lines),
            };
            cat.lines = reorderFromDuplicate(newLine, index, cat.lines);
            dispatch(updateCategoryData([cat]));
          }
          return line;
        });
        return cat;
      });
    } else if (from?.split("_")[0] === "CATEGORY") {
      toastMessage = "Category has been duplicated.";
      let categoryIndex = 0;
      catList?.map((cat, index) => {
        if (cat.category_id === categoryId) {
          newCategory = {
            ...copyCategory(cat, catList),
            prompt: figureOutNewNameForDuplicate(name, catList),
          };
          categoryIndex = index;
        }
        return cat;
      });
      catList = reorderFromDuplicate(newCategory, categoryIndex, catList);
      duplicateCategory();
    }
    if (newLine !== null) {
      setAllCategoryList((prev) => {
        const newCategoryList = { ...AllCategoryList };
        newCategoryList.template.categories = catList;
        newCategoryList.template.categories.updated_at = timestamp();
        return newCategoryList;
      });
      updateTemplate("showToast", toastMessage, true);
    }
  };

  const removeLine = (id, remove = 1) => {
    if (remove) setIsActive(false);
    else setIsActive(true);
    const catList = AllCategoryList?.template?.categories;
    catList?.map((cat) =>
      cat?.lines?.map((line) => {
        if (line?.line_id === lineId) {
          line?.line_input_controls?.map((ip) => {
            if (ip?.line_input_control_id === id) {
              ip.is_disabled = remove;
              ip.updated_at = timestamp();
              filterObjectsUptoCategory(catList, cat.id, dispatch, categories);
              updateTemplate("noToast");
            }
          });
        }
      })
    );
  };

  const checkForEmpty = (str) => {
    return str === undefined || str === null || str === "" || str === "Untitled";
  };

  const IsShadowing = (from) => {
    if (from?.split("_")[0] === "CATEGORY" && from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else if (from?.split("_")[0] === "CATEGORY" || from?.split("_")[0] === "details") {
      return "-5px 6px 8px 1px #e1e1e1";
    } else {
      return "none";
    }
  };

  useEffect(() => {
    setLineText(HeaderTitle);
  }, [updateTemplate]);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (categoryActive?.length === 0) {
      setIsActive(false);
    }
  }, [categoryActive]);

  const NewTitle = () => {
    return (
      <>
        Untitled
        <span
          style={{
            position: "relative",
            top: "2px",
            left: "5px",
            cursor: item?.is_disabled ? "" : "pointer",
          }}>
          <EditIcon />
        </span>
      </>
    );
  };

  const getCategoryDetails = () => {
    if (from?.split("_")[0] === "CATEGORY") {
      const formData = {
        template_id: templateId,
        category_id: categoryId,
      };
      let catList = AllCategoryList?.template?.categories;
      const categoriesDataIndex = catList.findIndex((cat) => cat.id === categoryId);
      if (!catList[categoriesDataIndex]?.lines || !catList[categoriesDataIndex]?.lines?.length) {
        apiCall(
          (response) => {
            const { data, message, success } = response.data;
            if (success) {
              catList[categoriesDataIndex].lines = data.lines || [];
              catList[categoriesDataIndex].introductory_text_dropdowns = data.introductory_text_dropdowns || [];
              setAllCategoryList((prev) => {
                const newCategoryList = { ...AllCategoryList };
                newCategoryList.template.categories = catList;
                newCategoryList.template.categories.updated_at = timestamp();
                return newCategoryList;
              });
            } else {
              console.error("else ", message);
            }
          },
          formData,
          "GET_CATEGORY_LINES"
        );
      }
    }
  };

  const assignFlagInCategory = (val, catUUID, CatID, selectedGuids) => {
    let flaggingGUIDs = [];
    let catList = AllCategoryList?.template?.categories;
    if (selectedGuids.includes(val?.guid)) {
      // Remove the guid from selectedGuids
      selectedGuids = selectedGuids.filter((guid) => guid !== val?.guid);
      // Push the updated selectedGuids to flaggingGUIDs
      flaggingGUIDs.push(...selectedGuids); // Use spread operator to push a copy
    } else {
      // Push the updated selectedGuids and the new guid to flaggingGUIDs
      flaggingGUIDs.push(...selectedGuids, val?.guid); // Push current state and new guid separately
    }
    catList?.forEach((category) => {
      if (category?.category_id === CatID) {
        category?.inspector_flagging_guids?.splice(0, category?.inspector_flagging_guids?.length, ...flaggingGUIDs);
        category.updated_at = timestamp();
        filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
        updateTemplate("noToast");
      }
    });
  };

  const assignFlagInLine = (val, lineData) => {
    // Initialize an array for flagging GUIDs
    let flaggingGUIDs = [];
    let catList = AllCategoryList?.template?.categories;
    // Add the GUID from the val object to the flagging GUIDs array
    flaggingGUIDs.push(val?.guid);
    catList?.forEach((cat) => {
      cat?.lines?.forEach((line) => {
        if (line?.line_id === lineData?.line_id) {
          if (!lineData?.inspector_flagging_guids) {
            lineData.inspector_flagging_guids = [...flaggingGUIDs];
            lineData.updated_at = timestamp();
          } else {
            // Retrieve the current guids from lineData
            let selectedGuids = lineData?.inspector_flagging_guids || [];
            if (selectedGuids.includes(val?.guid)) {
              selectedGuids = selectedGuids.filter((guid) => guid !== val?.guid);
            } else {
              selectedGuids.push(val?.guid);
            }
            lineData.inspector_flagging_guids.splice(0, lineData.inspector_flagging_guids.length, ...selectedGuids);
            lineData.updated_at = timestamp();
          }
        }
      });
    });
    filterObjectsUptoCategory(catList, categoryId, dispatch, categories);
    updateTemplate("noToast");
  };

  return (
    <>
      <Preloader showPreloader={showLoader} />
      <div
        style={{
          marginBottom: from?.split("_")[0] === "SUBCATEGORY" ? "0px" : from?.split("_")[0] === "DEFAULTS" ? "0" : "15px",
        }}>
        <div
          {...dragHandleProps}
          onClick={(event) => {
            event.stopPropagation();
            !isActive && getCategoryDetails();
            if (!item?.is_disabled) {
              setIsActive(!isActive);
              disableDnD && disableDnD(!isActive, item?.uuid);
              dispatch(setEditorListner(!isActive));
            }
          }}
          style={{
            borderRadius: BorderRadius(from),
            boxShadow: IsShadowing(from),
            position: "relative",
            zIndex: isActive && from?.split("_")[0] === "CATEGORY" && "1",
            borderBottom: BorderBottomAccord(from, accordOpen),
          }}
          className="accordition">
          <DragHandleIcon
            className="accordition__draggable"
            sx={{
              fontSize: "20px",
              mr: 1,
              color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#333333",
            }}
          />
          <Grid container sx={{ minWidth: 0 }}>
            <Grid item xs={9}>
              <div
                className={isMobile ? "accordition__title__mobile" : "accordition__title"}
                style={{
                  color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#333333",
                  padding: 0,
                }}>
                {(isTitleEdit === true && renId === selectedID && checkForEmpty(HeaderTitle)) || (isChanged && selectedID === renId) ? (
                  <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => ChangeCategoryText(from)}>
                    <input type="text" name="title" value={lineText} onChange={(e) => setLineText(e.target.value)} onFocus={handleFocus} autoFocus className="accordion_empty" />
                  </ClickAwayListener>
                ) : (
                  <div
                    onClick={(e) => (!item?.is_disabled ? RenameCategoryList(e, HeaderTitle, selectedID) : null)}
                    style={{
                      width: isMobile ? "94%" : item?.type === "Additional Input" && !item?.is_disabled ? "98%" : "100%",
                      paddingLeft: isMobile && "15px",
                      fontStyle: linedata?.optional === 1 && "italic",
                      opacity: linedata?.optional === 1 && "0.5",
                      whiteSpace: item?.type === "Additional Input" && item?.is_disabled ? "unset" : "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize: from?.split("_")[0] === "SUBCATEGORY" || from?.split("_")[0] === "DEFAULTS" ? "14px" : "18px",
                      display: item?.type === "Additional Input" && !item?.is_disabled ? "block" : "flex",
                      alignItems: "center",
                      marginLeft: item?.type === "Additional Input" && !item?.is_disabled && "4%",
                    }}
                    className={
                      item?.type !== "Additional Input" && item?.is_disabled ? "accordionTitleDisable" : item?.type === "Additional Input" && item?.is_disabled ? "additional_title_disabled" : ""
                    }>
                    {item?.type === "Additional Input" && item?.is_disabled ? (
                      checkForEmpty(HeaderTitle) && item?.type === "Additional Input" && item?.is_disabled ? (
                        ""
                      ) : (
                        <Typography
                          className={item?.is_disabled ? "accordionTitleDisable" : "accordionTitle"}
                          style={{
                            width: checkForEmpty(HeaderTitle) && from?.split("_")[0] !== "comments" ? "0px" : "180px",
                          }}>
                          {item?.prompt}
                        </Typography>
                      )
                    ) : from?.split("_")[0] === "comments" && manualPrompt !== undefined ? (
                      manualPrompt === 0 ? (
                        HeaderTitle ? (
                          HeaderTitle
                        ) : recommended === null ||
                          recommended
                            ?.replace(/<\/?[^>]+(>|$)/g, "")
                            ?.replace(/&nbsp;/g, " ")
                            ?.trim()?.length === 0 ? (
                          NewTitle()
                        ) : (
                          recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                        )
                      ) : checkForEmpty(HeaderTitle) ? (
                        recommended === null ||
                          recommended
                            ?.replace(/<\/?[^>]+(>|$)/g, "")
                            ?.replace(/&nbsp;/g, " ")
                            ?.trim()?.length === 0 ? (
                          NewTitle()
                        ) : (
                          recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                        )
                      ) : HeaderTitle?.length > 150 ? (
                        `${HeaderTitle?.substring(150, 0)}`
                      ) : (
                        HeaderTitle
                      )
                    ) : checkForEmpty(HeaderTitle) ? (
                      recommended === null ||
                        recommended
                          ?.replace(/<\/?[^>]+(>|$)/g, "")
                          ?.replace(/&nbsp;/g, " ")
                          ?.trim()?.length === 0 ? (
                        NewTitle()
                      ) : (
                        recommended?.replace(/<\/?[^>]+(>|$)/g, "")?.replace(/&nbsp;/g, " ")
                      )
                    ) : HeaderTitle?.length > 150 ? (
                      ` ${HeaderTitle?.substring(150, 0)}`
                    ) : (
                      <p style={{ fontSize: "inherit", overflowWrap: "hidden", overflow: "hidden", wordWrap: "break-word", wordBreak: "break-all", textOverflow: "ellipsis", whiteSpace: "nowrap", paddingRight: "5px" }}>
                        {HeaderTitle}
                      </p>
                    )}
                    {checkForEmpty(HeaderTitle) && from?.split("_")[0] !== "comments" && "Untitled"}
                    {linedata?.optional === 1 ? (
                      <span
                        style={{
                          position: "relative",
                          top: "3px",
                          left: "10px",
                          marginRight: "12px",
                        }}>
                        <BootstrapTooltip title={<h1 className="font-13">Optional items do not appear in the report by default, but can easily be added</h1>}>
                          <VisibilityOffIcon
                            sx={{
                              fontSize: "15px",
                            }}
                          />
                        </BootstrapTooltip>
                      </span>
                    ) : null}
                    {checkForEmpty(HeaderTitle) && from?.split("_")[0] !== "comments" && (
                      <span
                        style={{
                          position: "relative",
                          top: "2px",
                          left: "5px",
                          cursor: item?.is_disabled ? "" : "pointer",
                        }}>
                        <EditIcon />
                      </span>
                    )}
                    {item?.is_disabled ? (
                      <Typography className="titleDisable" ml={2} mt={0.8}>
                        {`*This form element has been removed and will not be published.`}
                      </Typography>
                    ) : null}
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={3} sx={{ pt: "2px" }}>
              {item?.is_disabled ? (
                <BootstrapTooltip title={<h1>Add form element</h1>}>
                  <AddCircleOutlineRoundedIcon sx={{ color: "#777777" }} onClick={() => removeLine(item?.line_input_control_id, 0)} />
                </BootstrapTooltip>
              ) : (
                <div style={{ display: "flex", alignItems: "end", justifyContent: "right", gap: "5px" }}>
                  <div style={{ display: "flex" }}>
                    {from?.split("_")[0] === "SUBCATEGORY" ? (
                      <>
                        {colorIndicatorList
                          ?.filter((val) => item?.inspector_flagging_guids?.includes(val?.guid) && val?.is_deleted !== 1)
                          ?.slice(0, 5)
                          ?.map((element) => {
                            return <FiberManualRecordIcon sx={{ color: element?.color_code }} />;
                          })}
                        {colorIndicatorList?.filter((val) => item?.inspector_flagging_guids?.includes(val?.guid) && val?.is_deleted !== 1)?.length > 5 && (
                          <div style={{ color: "#3b3b3b", fontWeight: 900 }}>
                            {`+${colorIndicatorList?.filter((val) => item?.inspector_flagging_guids?.includes(val?.guid) && val?.is_deleted !== 1)?.length - 5}`}
                          </div>
                        )}
                      </>
                    ) : from?.split("_")[0] === "CATEGORY" ? (
                      <>
                        {colorIndicatorList
                          ?.filter((val) => categoryInpectorFlags?.includes(val?.guid) && val?.is_deleted !== 1)
                          ?.slice(0, 5)
                          ?.map((element) => {
                            return <FiberManualRecordIcon sx={{ color: element?.color_code }} />;
                          })}
                        {colorIndicatorList?.filter((val) => categoryInpectorFlags?.includes(val?.guid) && val?.is_deleted !== 1)?.length > 5 && (
                          <div style={{ color: "#3b3b3b", fontWeight: 900 }}>
                            {`+${colorIndicatorList?.filter((val) => categoryInpectorFlags?.includes(val?.guid) && val?.is_deleted !== 1)?.length - 5}`}
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div style={{ marginLeft: isMobile && "10px" }}>
                    {from !== "details" &&
                      (actions?.menu === true && actions?.ActiveId === from ? (
                        <SectionMenu
                          AllCategoryList={AllCategoryList}
                          from={from}
                          assignFlagInCategory={assignFlagInCategory}
                          colorIndicatorList={colorIndicatorList}
                          linedata={linedata}
                          assignFlagInLine={assignFlagInLine}
                          isInspectionLines={isInspectionLines}
                          handleClose={actions?.CloseMenu}
                          handleOpen={actions?.OpenMenu}
                          open={actions?.menu}
                          anchorEl={actions?.anchorElMenu}
                          isInspection={isInspection}
                          handleOpenCI={() => setOpenColorIndicator(!openColorIndicator)}
                          RenameExisting={() => RenameExisting(selectedID)}
                          DeleteLines={() => DeleteLines(selectedID, from)}
                          duplicateCateOrLine={() => duplicateCateOrLine(selectedID, from, linedata?.prompt)}
                          removeLine={() => removeLine(selectedID)}
                          OpenEditableMenu={OpenEditableMenu}
                        />
                      ) : null)}
                  </div>
                  {isActive ? (
                    <ExpandLessIcon sx={{ color: linedata?.optional !== 1 && "#4282E2" }} className="accordition__open" />
                  ) : (
                    <ExpandMoreIcon sx={{ color: isActive && linedata?.optional !== 1 ? "#4282E2 !important" : "#3B3B3B !important" }} className="accordition__open" />
                  )}
                  <MoreHorizIcon onClick={(e) => actions?.OpenMenu(e, from)} sx={{ color: isActive && linedata?.optional !== 1 ? "#4282E2" : "#3B3B3B" }} />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        {isActive && (from?.split("_")[0] === "CATEGORY" ? categoryActive?.length > 0 : true) && (
          <Box
            sx={{
              padding: {
                md: AccordionEdges(from),
                lg: AccordionEdges(from),
                xs: "10px ",
                sm: "10px ",
              },
            }}
            className="accordion-content">
            <div>{innerTemplate}</div>
          </Box>
        )}
      </div>
      {skeletonLoad && duplicateIndex === duplicateCategoryId - 1 ? (
        <motion.div initial={{ opacity: 0, scale: 1, y: -50 }} animate={{ opacity: 1, scale: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Skeleton sx={{ height: "79px", marginTop: "-5px" }} />
        </motion.div>
      ) : null}
      <MainTemplateModal
        createNewSite={isEditable}
        title={`Edit "${HeaderTitle ?? "Untitled"}"`}
        type={"EditCategory"}
        HandleCreateSite={OpenEditableMenu}
        actions={{
          AddSiteDetaill: () => closeEditableMenu(),
        }}
        innerTemplate={
          <>
            <EditCategorys
              linedata={linedata}
              AllCategoryList={AllCategoryList}
              from={from}
              editableData={(name, text, optional, dropdowns) => editableData(name, text, optional, dropdowns)}
              setCommentTags={setCommentTags}
              commentTags={commentTags}
              commentListData={commentListData}
            />
          </>
        }
      />
      <ColorIndicators
        updateTemplate={updateTemplate}
        AllCategoryList={AllCategoryList}
        GetColorIndicatorList={GetColorIndicatorList}
        LoadList={LoadList}
        setColorIndicatorList={setColorIndicatorList}
        templateGUID={templateUuid?.guid}
        templateType={templateUuid?.template_type}
        data={colorIndicatorList}
        CloseOpenCI={() => setOpenColorIndicator(false)}
        openCI={openColorIndicator}
      />
    </>
  );
};

export default TemplateAccord;